<script>
export default {
  name: 'ClickOutside',
  async mounted() {
    // needs, because click event can be fired before nested component render
    requestAnimationFrame(() => {
      window.document.body.addEventListener('click', this.onClickAnyElement)
    })
  },
  beforeDestroy() {
    window.document.body.removeEventListener('click', this.onClickAnyElement)
  },
  methods: {
    onClickAnyElement(e) {
      if (!this.$slots.default) return

      const [root] = this.$slots.default

      if (!root) return

      const path = e.path || (e.composedPath && e.composedPath()) || []

      if (path.includes(root.elm)) return

      this.$emit('click')
    },
  },
  render() {
    return this.$slots.default
  },
}
</script>
